.hidden { display: none; }

.container {
  padding: 0;
}

.buildOverview {
  margin-bottom: 24px;
  z-index: 1;
  position: relative;

  h4 {
    color: var(--mdc-theme-primary);
    margin-top: 0;
    margin-bottom: 8px;
  }

  ul {
    margin-top: 0;
    margin-left: 0;
    padding-left: 0;
  }

  li { list-style: none; }
}

.buildLink { text-decoration: none; }