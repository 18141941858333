.markdown-container {
  position: relative;
}


.markdown-editor-title {
  border: 1px solid #e8e8e8;
}

.markdown-edit-toggle {
  position: absolute !important;
  right: 0px;
}

.markdown-body-preview {
  padding-left: 8px;
  border-left: 1px solid #e8e8e8;
}

.markdown-editor {
  display: flex;
  flex-wrap: nowrap;
  align-content: stretch;
 // position: absolute;
  left: 16px;
  right: 16px;
}

.markdown-grid-container {
  //overflow: scroll;
  height: 100%;
}

.markdown-editor-left-pane {
  flex: 1 1;
  overflow: scroll;
}

.markdown-editor-right-pane {
  flex: 1 1;
  overflow: scroll;
}

.markdown-container .ace_gutter {
  color: #A0AABF;
  background: #F5F7FA;
}

.ace_editor {
  line-height: 28px !important;

}

.ace_heading {
  font-weight: 600;
  line-height: 28px !important;
}

#markdown-editor-instance {
  font-family: Ubuntu Mono,Monaco;
  font-size: 1rem;
  line-height: 28px !important;
  font-weight: 400;
}

.markdown-editor-title {
  padding: 8px 8px;
}

.markdown-editor-title-text {
  color: var(--mdc-theme-primary);
}

.markdown-editor-title-text-right {
  color: var(--mdc-theme-primary);
}

.markdown-editor-save-icon {
  color: var(--mdc-theme-primary) !important;
  align-self: flex-end;
}

.markdown-toolbar-editor {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
}

.markdown-editor-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #e7e7e7;
  opacity: 0.5;
  z-index: 100;
}

.markdown-editor-save-progress {
  position: relative;
  left: 50%;
  top: 50%;
  transform:translate(-50%, -50%);
}

.markdown-page-container {
  display: flex;
  flex-direction: row-reverse;
}

.markdown-page-content-with-toc {
  margin-left: 280px;
  padding-left: 24px;
}


.markdown-page-toc {
  background-color: #fcfdfe;
  position: fixed;
  height: 100%;
  overflow-y: scroll;
  width: 280px;
  top: var(--fixed-app-bar-size);
}

.markdown-page-toc a {
  color: var(--mdc-theme-on-surface, #000000);
  text-decoration: none;
}

// create a hidden anchor element for scrolling into view
h3[id]::before, h2[id]::before, h1[id]::before, h4[id]::before,h5[id]::before, h6[id]::before, a[name]::before     {
  content: '';
  display: block;
  height: var(--fixed-app-bar-size);
  margin-top: var(--fixed-app-bar-size-negative);
}

@media(max-width: 1220px) {
  .markdown-page-toc {
    display: none;
  }

  .markdown-page-content-with-toc {
    margin-left: 0px;
    padding-left: 0px;
  }
}

@media(min-width: 1200px) {
  .top-header .top-header-title {
    padding-left: 0px;
  }
}

.markdown-page-toc li {
  list-style: none;
  line-height: 32px;
  text-overflow: ellipsis;
  font-weight: 300;
  font-size: 14px;
}

.markdown-page-toc ul {
  margin-left: 0;
  padding-left: 0;
}

.markdown-page-toc .toc-heading-1 {
  font-weight: 500 !important;
  font-size: 16px !important;
  padding-left: 8px;
}

.toc-heading-2 {
  padding-left: 24px;
  font-weight: 400 !important;
}

.toc-heading-3 {
  padding-left: 40px;
}

.toc-heading-4 {
  padding-left: 56px;
}

.toc-heading-5 {
  font-size: 14px;
  padding-left: 72px;
}

.toc-heading-6 {
  padding-left: 88px;
}

::-webkit-scrollbar-thumb {
  background: rgba(128, 134, 139, .26);
  border-radius: 8px;
}

pre::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track  {
  background: rgba(128, 134, 139, .26);
  border-radius: 8px;
}


.page-not-found {
  margin:auto;
}

.page-not-found ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.page-not-found li {
  margin: 8px 0 8px 0;
}

.embedded-content {
  width: 100%;
  min-height: 100vh;
  border: none;
}

.csv-linechart, .timelinechart-container {
  width: 100%;
  height: 100%;
  padding-bottom: 16px;
}

.timelinechart-tooltip {
  position: absolute;
  z-index: 10;
  visibility: hidden;
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 4px;
  padding: 8px;
  background-color: white;
  max-height: 300px;
  overflow-x: scroll;
}

.csv-line, .csv-line-1, csv-line-2 {
  fill: none;
  stroke-width: 2px;
  clip-path: url(#clip);
}

.circle-id-v1, .lines-id-v1 {
  clip-path: url(#clip);
}

.linesAndAxisContainer {
  text.mdc-typography--body2 {
    font-weight: 500;
  }
}

.csv-legend-item {
  display: inline-block;
  margin-right: 24px;
  margin-bottom: 16px;
}

.csv-legend-item-color {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: top;
}

.zoom {
  cursor: move;
  fill: none;
  pointer-events: all;
}
