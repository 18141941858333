$mdc-theme-primary: #0366d6 !default;
$mdc-theme-secondary: #0366d6 !default;

@use "@material/theme" with (
  $primary: #0366d6,
  $secondary: #0366d6,
  $background: #fff
);
@use "@material/typography" with (
  $font-family: unquote("-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol")
);


@import "material-components-web/material-components-web.scss";
@import '~@rmwc/circular-progress/circular-progress.css';
@import '~@rmwc/icon/icon.css';
@import '~@rmwc/data-table/data-table.css';
@import '~@rmwc/list/collapsible-list.css';
@import '~@rmwc/tooltip/tooltip.css';

//FIXME, bug in MDC
.mdc-list-divider {
  list-style-type: none;
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 50px;
}

:root {
  --mdc-theme-secondary: #0366d6;
  --fixed-app-bar-size: 50px;
  --fixed-app-bar-size-negative: -50px;
  --spotlight-code-font: Consolas, "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
  --spotlight-code-color: rgb(57, 58, 52);
  --spotlight-light-grey: #fcfdfe;
}

body {
  min-height: 100vh;
  margin: 0px;
  margin-left: calc(100vw - 100%);
}


a, a:visited, a:hover, a:active {
  color: var(--mdc-theme-primary);
}

a, a:visited, a:hover, a:active {
  color: var(--mdc-theme-primary);
}

.markdown-body {
  box-sizing: border-box;
  min-width: 200px;
  //max-width: 980px;
  margin: 0 auto;
}


code {
  background-color: rgba(31, 51, 73, 0.05)
}

.details {
  margin-top: -48px;
  line-height: 1.5em;
}

.install-button {
  margin-right: 16px;
}

.main-panel {
  padding-left: 8px;
  padding-right: 8px;
  margin: auto;
  max-width: 1200px;
  width: 100%;
}

.content {
  width: 100%;
  //max-width: 900px;

  position: relative;
}

.settingsPane {
  width: 100%;
  //max-width: 900px;
}

.drawer-container {
  display: flex;
  flex-direction: row;
}

.drawer-app-content {
  flex: auto;
  overflow: auto;
}

.top-app-bar-fix-adjust {
  display: flex;
  width: 100%;
}

.projects-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-left: -8px;
}

.new-project-card {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--mdc-theme-primary);
  padding: 24px 24px;
}

.project-card {
  min-width: 280px;
  margin: 8px;
 }



.project-card-big-icon {
  font-size: 48px;
}

.project-card__title {
  padding: 0 16px 0 16px;
}

.project-card__secondary {
  padding: 0 16px 0 16px;
}

.popupDialog {
  min-width: 320px;
}

.addMemberDialog {
  min-width: 120em;
}

.example-code-block {
  margin: 24px 0 24px 0;
}
.example-code-block code {
  padding: 24px;
}
