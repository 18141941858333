// FIXME rename this to be "project" wide css
.build-title {
  color: var(--mdc-theme-primary);
}

a.download-link, a:visited.download-link {
  color: #0366d6 !important;
  text-decoration: none;
}

.build-file-list {
  list-style: none;
  border-left: .25rem solid var(--mdc-theme-primary);
  margin: 16px 0 16px 0px;
  padding: 8px 0px 8px 0px;
}

.build-file-item {
  padding: 8px 0px 0px 8px;
}

.build-info {
  list-style: none;
  border-left: none;
  margin: 0px;
  padding: 0 0 0 0;
  flex-grow: 1;
}

.build-container {
  margin-bottom: 16px;
  align-items: flex-end;
  position: relative;
  width: 100%;
}

.condensed-build-list {
  list-style: none;
  border-left: .25rem solid var(--mdc-theme-primary);
  margin: 16px 0 16px 0px;
  padding: 8px 0px 8px 0px;
}

.build-container-all-dense .build-title {
  color: var(--mdc-theme-on-surface);
}

.build-container-all {
  //margin-top: 8px;
  flex-grow: 2;
}

.build-container-all-dense {
  margin-left: 16px;
}



.build-container-version-list {
  margin: 16px 0px 16px 0px;
  color: var(--mdc-theme-secondary);
}

.build-actions {
  margin: 8px 0px 8px 0px;
}

.build-action-menu-button {
  margin-right: -16px;
  color: var(--mdc-theme-primary) !important;
}

.build-container-version-list span {
  vertical-align: middle;
}

.build-container-version-list i {
  vertical-align: middle;
}

.build-info-inline-ts {
  margin-top: -8px;
}

.build-info-downloadable-file {
  color: var(--mdc-theme-primary);
  padding: 0px 0px 8px;
}

.overview-container .markdown-container {
  width: 100%;
  min-width: 0;
}

.overview-container {
  display: flex;
}

.condensedBuild {
  display: flex;
}

.multiple-builds .markdown-page-content {
  max-height: 35vh;
  overflow: hidden;
}

.builds-list {
  display: inline-block;
  margin: 8px 0px 8px 0px;
  max-width: 90vw;
}

.builds-list dd {
  margin: 0 0 0 24px;
  flex-shrink: 0;
}

.builds-list dt {
  flex-grow: 1;
  margin-bottom: 8px;
  flex-basis: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.builds-list .active * {
  font-weight: bolder;
}


.builds-list div {
  display: flex;
  align-items: baseline;
  width: 100%;
  font-family: var(--spotlight-code-font);
}

.builds-list-title {
  margin: 8px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--mdc-theme-primary);
}

.build-info li > div {
  display: flex;
}

.builds-list-title:hover {
  cursor: pointer ;
}

.builds-list-title .rmwc-icon {
  font-size: inherit;
}

.rmwc-collapsible-list.rmwc-collapsible-list--open .builds-list-title  .rmwc-icon {
 transform: rotate(90deg);
 transition: transform 0.3s;
}

:not(.rmwc-collapsible-list--open) .builds-list-title  .rmwc-icon {
  transform: rotate(0deg);
  transition: transform 0.3s;
 }

.build-menu-actions {
  color: var(--mdc-theme-primary);
}

.build-menu-actions button {
  font-size: 24px
}

.build-menu-small-icon {
  color: var(--mdc-theme-primary);
  fill: var(--mdc-theme-primary);
  stroke: var(--mdc-theme-primary);
  font-size: 20px;
  margin-left: 1rem;
}

.build-title ~ span {
  flex-grow: 1;
}

.install-button {
  z-index: 1;
}