
.create-account-panel {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  max-width: 320px !important;
  text-align: center;
}

.create-account-panel-options {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}

.create-account-panel-options > * {
  margin-top: 8px;
  margin-bottom: 8px;
}

.create-account-panel-options > form {
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
}

.create-account-panel-options > form > * {
  margin-top: 8px;
  margin-bottom: 8px;
}

.invalid-field-caption {
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  color: var(--mdc-theme-error);
}

.accept-invite-error {
  margin-top: 24px;
}