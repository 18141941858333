.wrapper {
  width: 100%;

  .tableContainer {
    position: fixed;
    bottom: 0;

    .table {
      overflow: hidden;

      width: 100%;
      table-layout: fixed;

      .fileCell {
        width: 20%;
      }

      .lineCell {
        width: 10%;
      }

      .methodCell {
        width: 30%;
      }

      .tagCell {
        width: 20%;
      }

      .dateCell {
        width: 20%;
      }
    }
  }
}
