body {
  margin-top: 0px;
}

.top-header {
  padding-top: 0px;
  margin: auto;
  max-width: 1200px;
}

.top-header > .mdc-top-app-bar {
  background-color: white;
  color: var(--mdc-theme-primary);
  max-width: 1200px;
  box-shadow: none;
  border-bottom: solid 1px #EAECF2;
}

.top-bar-actions-section {
  flex-grow: 0;
}

.header-tabs-mobile {
  display: none;
}

.header-tabs-mobile-section {
  max-width: 100vw;
}

.top-header .top-header-title {
  padding-left: 8px;
}

@media (min-width: 640px) {
  .top-header .mdc-tab-bar {
    width: auto;
   }
}

.mdc-tab-scroller__scroll-content {
  justify-content: flex-end;
}

@media (max-width: 640px) {
  .header-tabs-desktop {
    display: none;
  }
  .header-tabs-mobile {
    display: block;
  }
  .top-header {
    margin-bottom: 16px;
  }
  .top-header .top-header-title {
    padding-left: 8px;
  }
  :root {
    --fixed-app-bar-size: 250px;
    --fixed-app-bar-size-negative: -250px;
  }
  .drawer-container {
    padding-top: 24px;
  }
  .top-header .top-header-title {
    padding-left: 8px;
  }
}


.top-header .mdc-tab {
  text-transform: capitalize;
}

.main-menu {
  min-width: 240px;
}

.top-header-spacer {
  flex-grow: 2;
  width: 100%;
}


.separator {
  margin-left: 8px;
  margin-right: 8px;
}

.top-header-text {
  font-size: 18px !important;
  font-weight: 700 !important;
  padding-left: 8px;
}

.inuit-project-title {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.top-header-menu-spacer {
  vertical-align: middle;
}


.account-button {
  vertical-align: middle;
}

.full-page-loader {
  position: fixed;
  left: calc(50% - 64px);
  top: calc(50% - 64px);
}

#rmwcPortal a, #rmwcPortal a:hover {
  color: inherit;
}

.footer {
  padding: 20px;
}
