.login-panel {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  max-width: 320px !important;
  text-align: center;
}

.firebase-login-container {
  margin-top: 16px;
  margin-bottom: 16px;
  min-width: 320px;
}

.login-welcome-title {
  align-self: center;
}