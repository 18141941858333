.swagger-ui {
  font-family: unset;
}

.swagger-ui pre {
  font-family: var(--spotlight-code-font);
  font-size: unset;
}


.swagger-ui .wrapper {
  padding: 0;
}

.swagger-ui .info {
  margin: 0;
}

.swagger-ui code,
.swagger-ui kbd,
.swagger-ui samp {
	font-family: var(--spotlight-code-font);
	font-size: 16px
}


.swagger-ui .markdown code, .swagger-ui .renderedMarkdown code {
  color: var(--mdc-theme-primary);
  font-family: var(--spotlight-code-font);
  font-size: unset;
}

.swagger-ui .info li, .swagger-ui .info p, .swagger-ui .info table {
  font-family: unset;
  font-size: unset;
}

.swagger-ui .markdown {
  line-height: 1.5;
}

.swagger-ui .scheme-container {
  display: none;
}