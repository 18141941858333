.file-browser-file-list {
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
}

.file-browser-container {
  position: relative;
}

.file-browser-toolbar {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.file-browser-toolbar div.action-toolbar {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.file-browser-container table {
  width: 100%;
}

.file-browser-container .cell-selected {
  overflow: visible;
}

.file-browser-container th.cell-selected {
 width: 48px;
}

.file-browser-container th.cell-size {
  width: 120px;
}

.file-browser-container th.cell-contentType {
  width: 192px;
}

.file-browser-container th.cell-updatedAt {
  width: 240px;
}

.file-browser-container td p {
  white-space: break-spaces;
  overflow: hidden;
  max-height: 2.5em;
  margin: 0;
}

@media (max-width: 800px) {
  .file-browser-container .cell-updatedAt {
    display: none;
  }
}

@media (max-width: 600px) {
  .file-browser-container .cell-selected {
    display: none;
  }

  .file-browser-container .cell-contentType {
    display: none;
  }
  .file-browser-container .cell-size {
    display: none;
  }
}

.file-breadcrumbs {
  display: inline-block;
}

.file-browser-breadcrumbs {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.file-browser-breadcrumbs i {
  font-size: inherit;
}

.file-browser-file-list {
  display: flex;
}

.file-browser-file-list .mdc-data-table {
  flex-basis: 0;
  flex-grow: 1;
  border-width: 0px;
}

.file-browser-file-list .mdc-data-table.extended {
  border-right-width: 1px;
}


.file-info-container {
  width: 240px;
  background-color: var(--spotlight-light-grey);
  padding: 8px 16px 24px 24px;
  text-overflow: ellipsis;
}

.file-info-container dd {
  color: var(--mdc-theme-on-surface);
  margin: 0 0 16px 0;
  font-size: 0.9rem;
  overflow: hidden;
  word-wrap: break-word;
  
}

.file-info-container dt {
  color: var(--spotlight-code-color);
  font-size: 0.7rem;
}

.file-info-container-header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.file-info-container-header span {
  flex-basis: 0;
  flex-grow: 1;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.file-browser-selection {
  position: absolute;
  z-index: 3;
  background-color: var(--mdc-theme-primary);
  width: 100%;
  height: 56px;
  border-radius: 4px;
  color: var(--mdc-theme-on-primary);
  display: flex;
  align-items: center;
}

.file-browser-selection > * {
  margin-right: 16px;
}