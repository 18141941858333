.container {
  padding: 16px 0;
  margin-bottom: 24px;
  &:last-child {margin-bottom: 0;}
  background-color: var(--spotlight-light-grey);
}

.graph {
  width: 100%;
  height: 250px;
}

.label{
  display: inline-block;
  margin-bottom: 8px;
}