$size-tablet: 600px;
$size-desktop: 840px;
@mixin size-phone {
  @media (max-width: #{$size-tablet - 1px}) { @content; }
}

@mixin size-tablet {
  @media (max-width: #{$size-desktop - 1px}) and (min-width: $size-tablet) { @content; }
}

@mixin size-desktop {
  @media (min-width: $size-desktop) { @content; }
}