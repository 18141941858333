.create-new-key-button {
  float: right;
}

.add-member-button {
  float: right;
}

.key-head-cell {
  width: 30ch;
}

.settings-page {
  margin-right: 24px;
  max-width: 936px;
  width: 100%;
}

.settings-card {
  padding: 8px 8px 8px 8px;
}

.settings-keys-table {
  width: 100%;
}

.hide-cs {
  width: 100%;
}

.permissions-list {
  list-style-type: none;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

.permission-list-item {
  height: 48px !important;
}

.permission-list-item-action-container {
  display: flex;
  justify-content: flex-end;
}

.settings-headline {
  margin: 1rem 0;
}

.settings-body {
  margin: 16px 0;
}