.ios-install-setup {
  list-style: none;
  border-left: .25rem solid var(--mdc-theme-primary);
  margin: 16px 0 16px;
  padding: 8px 8px 8px 24px;
}

.ios-install-setup-step {
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--mdc-theme-primary)
}

.ios-install-setup-title {
  color: var(--mdc-theme-primary);
  margin-bottom: 16px;
  font-weight: 700;
}

.install-app-title {
  color: var(--mdc-theme-primary);
  font-weight: 700;
}

.qr-code-container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}

.qr-code-view {
  margin-right: 24px;
  margin-bottom: 24px;
}

.qr-code-help {
  border-left: .25rem solid var(--mdc-theme-primary);
  margin: 0px 0 24px 0px;
  flex-basis: 120px;
  flex-grow: 2;
}

.qr-code-help>li {
  margin-top: 8px;
  margin-bottom: 8px;
}
li.qr-code-help-strong  {
  font-weight: 500;
  margin-bottom: 16px;
  line-height: 24px;
}

.not-on-mobile-headline {
  margin-top: 16px;
}
