@import "~styles/sizes.scss";

.logsContainer {
  color: rgba(0, 0, 0, 0.87);
  font-size: small;
  width: 100%;
  position: relative;
}

.deviceInfoToggle {
  z-index: 10;
  position: absolute;
  top: 8px;
  right: 31px;
  
  :global {
    .mdc-icon-button.mdc-ripple-upgraded--background-focused::before {
      background-color: #fff;
    }
  }
}

.closeButton {
  cursor: pointer;
  padding: 0 0 0 1px;
  border-radius: 2px;
}

.logsWrapper {
  transition: height 0.66s ease-out;
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,.12);
  padding: 0px;
}

.logSession {
  position: relative;
  grid-gap: 0;
}

.deviceInfo {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  background-color: var(--spotlight-light-grey);
}

.loader {
  width: 100%;
}

@mixin mobile-layout {
  .deviceInfo { grid-area: deviceInfo 1 / 1 / span 1 / 12; }
  .logs { grid-area: logs 2 / 1 / span 1 / 12; }
}

@include size-tablet { @include mobile-layout; }
@include size-phone { @include mobile-layout; }

