@import "~styles/sizes.scss";

.deviceInfoTableWrapper {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 122px);
}

.value {
  flex: 1;
  color: var(--mdc-theme-on-surface);
  white-space: normal !important;
  word-break: break-all;
}

.chart {
  @extend .value;
  min-width: 150px;
  min-height: 100px;
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 100%;
  padding: 16px;

  .title {
    word-break: break-all;
    flex-grow: 1;
    width: 200px;
  }
}

.deviceInfoItem {
  padding: 8px 16px;
}

.deviceInfoItemTitle {
  color: var(--spotlight-code-color);
  font-size: .7rem;
  margin: 0px;
}

.deviceInfoItemValue {
  color: var(--mdc-theme-on-surface);
  font-size: .9rem;
  margin: 0 0 16px;
}

@mixin mobile-layout {
  .deviceInfoTableWrapper {
    height: auto;
    overflow-y: auto;
  }
}

@include size-tablet { @include mobile-layout; }
@include size-phone { @include mobile-layout; }


