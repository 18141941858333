
.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0,0,0,.12);
  border-radius: 4px;
}

.row {
  display: flex;
  flex-direction: row;
}

.cell {
  font-size: var(--mdc-typography-body2-font-size,.875rem);
  font-weight: var(--mdc-typography-body2-font-weight,400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing,.0178571429em);
  flex-grow: 1;
  flex-basis: 0;
}

.header {
  display: flex;
  flex-direction: column;

  .cell {
    font-size: var(--mdc-typography-subtitle2-font-size,.875rem);
    line-height: var(--mdc-typography-subtitle2-line-height,1.375rem);
    font-weight: var(--mdc-typography-subtitle2-font-weight,500);
    padding: 20px 15px;
  }
}

.body {
  display: flex;
  flex-direction: column;

  .row {
    border-top: 1px solid rgba(0,0,0,.12);

    &:hover {
      background-color: rgba(0,0,0,.04)
    }

    .cell {
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 15px;
    }
  }
}

.multiRow {
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0,0,0,.12);

  &:hover {
    background-color: rgba(0,0,0,.04)
  }

  .row {
    border: none;

    &:hover {
      background-color: transparent;
    }

    .cell {
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 15px;
    }
  }
}
