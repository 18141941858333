.typography {
  display: flex;
  flex-grow: 1;
  align-items: center;

  button {
    display: inline;
    cursor: pointer;
    text-decoration: underline;
    background: none !important;
    border: none;
    outline: none;
    padding: 0 !important;
    color: var(--mdc-theme-primary);
    font: inherit;
    -moz-user-select: text;

    i {
      font-size: inherit;
      vertical-align: middle;
    }
  }
}

.separator {
  font-size: inherit;
}