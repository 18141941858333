.diagnosticGrid {
  .searchGrid {
    margin: 8px 0px 24px;
  }

  .tabSeparator {
    margin: 0px;
  }

  .tabItem {
    height: 40px;
  }

  .overrideDiagnosticTable {
    .clickableRow {
      cursor: pointer;
    }

    .fadedMessageCell {
      color: var(--mdc-theme-text-secondary-on-background,rgba(0,0,0,.54));
      padding-top: 0px;
    }
  }
}
