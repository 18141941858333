#build-upload-input {
    display: none;
}

.upload-file-button, .files-to-upload {
    margin-top: 1rem;
}

.files-to-upload-item {
    display: flex;
    align-items: center;
}

.files-to-upload-item-button {
    align-self: flex-end;
    margin-left: 1rem;
}