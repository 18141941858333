
.error-container {
  margin: auto;
  max-width: 480px;

}

.error-message {
  text-align: center;
}

.error-help-line-container {
  margin-bottom: 16px;
  text-align: center;
}


.error-help-links {
  text-align: center;
}