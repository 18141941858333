.add-member-dialog-content {
  min-width: 30em;
}

.add-member-dialog-email-field {
  width: 100%;
  margin-top: 8px;
}

.dialog-right-top-header {
  margin-top: 16px;
  float: right;
}


.link-sharing-options {
  display: flex;
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.link-sharing-options-button {
  flex-grow: 0;
}

.link-sharing-options-link {
  flex-grow: 1;
  flex-shrink: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}