.grid {
  padding: 0px;
  margin: 0px;
  width: 100%;

  .title {
    margin-bottom: 8px;
  }

  .row1 {
    grid-gap: 8px;

    .search{
      margin: 8px 0px;
      width: 100%;
    }
  }

  .cellSearchSubmit {
    display: flex;
    justify-content: flex-end;
  }

  .row2 {
    .row2Wrapper {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;

      .dateRangeWrapper {
        display: flex;
        flex-flow: row wrap;

        .datePickerFirstWrapper {
          margin-right: 8px;
        }

        .datePickerWrapper {
          margin-top: 8px;
          margin-bottom: 8px;
          display: flex;

          .datePickerClear {
            margin-top: 5px;
            margin-left: 4px;
          }
        }
      }

      .searchSubmit {
        height: 100%;
        align-self: flex-end;

        .submit {
          margin-bottom: 8px;
          padding: 28px 24px;
        }
      }
    }
  }

  .datePicker {
    font-size: 16px;
    padding: 16px 24px;
    margin: 8px 0;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    cursor: pointer;

    &:disabled {
      border: 1px solid rgba(0, 0, 0, 0.06);
      background-color: white;
    }
  }
}

@media (max-width: 839px) {
  .grid {
    .row1 {
      grid-gap: 0px;
    }
  }
}